<template>
  <div style="overflow: hidden;">
    <el-table :data="planData" style="width: 100%;" max-height="200" border v-loading="loading">
      <el-table-column prop="sfaadocno" label="工单号" width="170"></el-table-column>
      <el-table-column label="单据日期" width="120">
        <template slot-scope="scope">
          {{scope.row.sfaadocdt?scope.row.sfaadocdt.substring(0, 10):''}}
        </template>
      </el-table-column>
      <el-table-column prop="oofa011" label="生管人员" width="80"></el-table-column>
      <el-table-column prop="sfaa019" label="预计开工日期" width="120">
        <template slot-scope="scope">
          {{scope.row.sfaa019?scope.row.sfaa019.substring(0, 10):''}}
        </template>
      </el-table-column>
      <el-table-column prop="sfaa020" label="预计完工日期" width="120">
        <template slot-scope="scope">
          {{scope.row.sfaa020?scope.row.sfaa020.substring(0, 10):''}}
        </template>
      </el-table-column>
      <el-table-column prop="sfaa012" label="生产数量" width="80"></el-table-column>
      <el-table-column prop="sfaa050" label="已入库合格量"  width="110"></el-table-column>
      <el-table-column prop="xmdddocno" label="已入库时间" width="160"></el-table-column>
      <el-table-column prop="planFeedbackTime" label="计划反馈时间" width="160"></el-table-column>
      <el-table-column prop="planDeliveryTime" label="预计发货日期" width="120">
        <template slot-scope="scope">
          {{scope.row.planDeliveryTime?scope.row.planDeliveryTime.substring(0, 10):''}}
        </template>
      </el-table-column>
      <el-table-column prop="actualDeliveryTime" label="实际发货日期" width="120">
        <template slot-scope="scope">
          {{scope.row.planDeliveryTime?scope.row.planDeliveryTime.substring(0, 10):''}}
        </template>
      </el-table-column>
      <el-table-column label="备注" width="160">
        <template slot-scope="scope">
          <el-popover placement="top-start" title="备注" width="260" trigger="hover">
            <div>{{ scope.row.remark }}</div>
            <span slot="reference">
              {{scope.row.remark && scope.row.remark.length > 20? scope.row.remark.substring(0, 20) + "..." + '' : scope.row.remark}}
            </span>
          </el-popover>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="80" v-if="hasAuth('sys:serve:jhSub')">
        <template slot-scope="scope">
          <el-button @click.native.prevent="changeRow(scope.row)" icon="el-icon-edit" type="text">
            修改
          </el-button>
          <!-- <el-divider direction="vertical"></el-divider> -->
<!--          <template>
            <el-popconfirm title="确定删除此数据吗？" @confirm="deleteRow(scope.row)">
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template> -->
        </template>
      </el-table-column>
    </el-table>
<!--    <el-pagination @current-change="handleCurrentChange" :current-page="page" :page-size="rows"
      layout="total, prev, pager, next, jumper" :total="total">
    </el-pagination> -->
    <el-dialog title="修改详情页" :visible.sync="dialogVisible" width="80%" top="12vh" @close="handleClose"
      :modal-append-to-body="false" :append-to-body="true" class="dialog">
      <div class="cont-box">
        <el-form :model="curData02" :rules="rule02" ref="curData02" class="list-cont01">
          <el-form-item label="工单号:" prop="sfaadocno">
            <el-input v-model="curData02.sfaadocno" size="medium" disabled></el-input>
          </el-form-item>
          <el-form-item label="单据日期:" prop="sfaadocdt">
            <el-input v-model="curData02.sfaadocdt.substring(0, 10)" size="medium" disabled></el-input>
          </el-form-item>
          <el-form-item label="生管人员:" prop="oofa011">
            <el-input v-model="curData02.oofa011" size="medium" disabled></el-input>
          </el-form-item>
          <el-form-item label="预计开工日期:" prop="sfaa019">
            <el-input v-model="curData02.sfaa019.substring(0, 10)" size="medium" disabled></el-input>
          </el-form-item>
          <el-form-item label="预计完工日期:" prop="sfaa020">
            <el-input v-model="curData02.sfaa020.substring(0, 10)" size="medium" disabled></el-input>
          </el-form-item>
          <el-form-item label="生产数量:" prop="sfaa012">
            <el-input v-model="curData02.sfaa012" size="medium" disabled></el-input>
          </el-form-item>
          <el-form-item label="已入库合格量:" prop="sfaa050">
            <el-input v-model="curData02.sfaa050" size="medium" disabled></el-input>
          </el-form-item>
          <el-form-item label="预计完工日期:" prop="qualifiedTime">
            <el-input v-model="curData02.qualifiedTime" size="medium" disabled></el-input>
          </el-form-item>
          <el-form-item label="计划反馈时间:" prop="planFeedbackTime">
            <el-date-picker v-model="curData02.planFeedbackTime" type="datetime"
              placeholder="选择时间" size="medium" value-format="yyyy-MM-dd HH:mm:ss">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="预计发货日期:" prop="planDeliveryTime">
            <el-date-picker v-model="curData02.planDeliveryTime" type="date" value-format="yyyy-MM-dd"
              placeholder="选择日期" size="medium">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="实际发货日期:" prop="actualDeliveryTime">
            <el-date-picker v-model="curData02.actualDeliveryTime" type="date" value-format="yyyy-MM-dd"
              placeholder="选择日期" size="medium">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="备注:" prop="remark">
            <el-input v-model="curData02.remark" placeholder="请输入" size="medium" type="textarea" :rows="2"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="subCont('curData02')">确认（计划）</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  export default {
    name: 'PlanList',
    props: ['planData'],
    data() {
      return {
        dialogVisible: false,
        loading: false,
        // page: 1,
        // rows: 4,
        // total: 100,
        tableData: [],
        curData02: {
          id:'',
          planFeedbackTime: '',
          planDeliveryTime: '',
          actualDeliveryTime: '',
          sfaadocno: '',
          sfaadocdt: '',
          oofa011: '',
          sfaa019: '',
          sfaa020: '',
          sfaa012: '',
          sfaa050: '',
          qualifiedTime: '',
          remark: ''
        },
        rule02: {
          planFeedbackTime: [{
            required: true,
            message: '请选择',
            trigger: 'change'
          }],
          planDeliveryTime: [{
            required: true,
            message: '请选择',
            trigger: 'change'
          }],
          actualDeliveryTime: [{
            required: true,
            message: '请选择',
            trigger: 'change'
          }],
          remark: [{
            required: true,
            message: '请输入',
            trigger: 'blur'
          }],
        }
      }
    },
    mounted() {

    },
    methods: {
      changeRow(data) {
        this.dialogVisible = true
        this.curData02 = data
      },
      handleCurrentChange(val) {
        this.page = val
      },
      resetForm(formName) {
        this.$refs[formName].resetFields();
      },
      handleClose() {
        // this.resetForm('curData01')
        // this.resetForm('curData02')
      },
      subCont(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$axios.post('/three-week-planner/save', this.curData02).then(res => {
              this.dialogVisible = false
              this.$message({
                message: '添加成功',
                type: 'success'
              })
            })
          } else {
            return false;
          }
        });
      },
    }
  }
</script>

<style scoped>
  .el-table {
    margin-top: 20px;
  }
  .el-table>>>.cell .el-button {
    padding: 0;
  }
  .dialog >>> .el-dialog__body{
    padding: 0 20px;
  }
  .list-cont01 {
    display: flex;
    flex-wrap: wrap;
    margin: 2em 1em 0;
    padding: 14px 0;
  }

  .list-cont01>div {
    width: 25%;
    display: flex;
    padding: 6px 0;
    align-items: center;
    margin-bottom: 0;
  }

  .title {
    line-height: 34px;
    background: #f9f9f9;
    text-indent: 1em;
    color: #999999;
  }

  .list-cont li>label {
    white-space: nowrap;
  }

  .list-cont01>div>>>.el-form-item__label {
    white-space: nowrap;
  }

  .list-cont01>div>>>.el-form-item__content {
    flex: 1;
    margin: 0 1em 0 .5em;
  }
  .el-date-editor.el-input, .el-date-editor.el-input__inner{
    width: 100%;
  }
</style>
